import React from 'react'
import './mainslider.scss';

export default class MainSlider extends React.Component {

	state = {
		slides: venueData.plantImages
	}

  componentDidMount(){
    $(".venue_slider .bxslider").bxSlider({
      slideMargin: 0,
      speed: 700,
      startSlide: 0,
      auto: true,
      speed: 500,
      pager: true,
      controls: true,
      randomStart: !1,
      preloadImages: "all",
      adaptiveHeight: true,
      responsive: !0,
      touchEnabled: true,
      nextText: "",
      prevText: "",

      onSliderLoad: function(e) {}
    });
  }

  renderItem(item){
    return (
      <li key={`slide_${item.id}`} style={{backgroundImage: `url(${item.url})`}}></li>
    );
  }

  render () {

    if(this.state.slides.length == 0){
      return (
        <div className="cleafix" style={{height: '180px'}}></div>
      );
    }

    return (
      <div className="venue_slider ">
        <ul className="bxslider">
          {this.state.slides.map(this.renderItem)}
        </ul>
      </div>
    );
  }
}
