import React from 'react';
import './facility.scss';

import RoomsAvailability from '../RoomsAvailability';
import RoomsAdditionalFacilities from '../RoomsAdditionalFacilities';
import FacilityBeverage from '../FacilityBeverage';

export default class Facilities extends React.Component {

	componentDidMount(){

	}



  render () {
    return (


        <div className="all-facility">
          <FacilityBeverage />
          <RoomsAvailability />
        </div>


    );
  }
}
