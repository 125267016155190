import React from 'react';

import './VenueMap.scss';

export default class VenueMap extends React.Component {

	componentDidMount(){
		let t = {
        styles: [{"featureType":"administrative","elementType":"all","stylers":[{"saturation":"-100"}]},{"featureType":"administrative.province","elementType":"all","stylers":[{"visibility":"off"}]},{"featureType":"landscape","elementType":"all","stylers":[{"saturation":-100},{"lightness":65},{"visibility":"on"}]},{"featureType":"poi","elementType":"all","stylers":[{"saturation":-100},{"lightness":"50"},{"visibility":"simplified"}]},{"featureType":"road","elementType":"all","stylers":[{"saturation":"-100"}]},{"featureType":"road.highway","elementType":"all","stylers":[{"visibility":"simplified"}]},{"featureType":"road.arterial","elementType":"all","stylers":[{"lightness":"30"}]},{"featureType":"road.local","elementType":"all","stylers":[{"lightness":"40"}]},{"featureType":"transit","elementType":"all","stylers":[{"saturation":-100},{"visibility":"simplified"}]},{"featureType":"water","elementType":"geometry","stylers":[{"hue":"#ffff00"},{"lightness":-25},{"saturation":-97}]},{"featureType":"water","elementType":"labels","stylers":[{"lightness":-25},{"saturation":-100}]}],
        center: new google.maps.LatLng(venueData.visitingAddress.lat, venueData.visitingAddress.long),
        zoom: 15,
        zoomControl: !0,
        zoomControlOptions: {
            style: google.maps.ZoomControlStyle.DEFAULT
        },
        disableDoubleClickZoom: !0,
        mapTypeControl: !1,
        mapTypeControlOptions: {
            style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR
        },
        scaleControl: !0,
        scrollwheel: !1,
        panControl: !0,
        streetViewControl: !1,
        draggable: !0,
        overviewMapControl: !0,
        overviewMapControlOptions: {
            opened: !1
        },
        mapTypeId: google.maps.MapTypeId.ROADMAP
    }



      , a = document.getElementById("map")
      , r = new google.maps.Map(a,t);

    // Map needle of the room
    let marker = new google.maps.Marker({
        icon: "assets/images/mapneedle.png",
        position: new google.maps.LatLng(venueData.visitingAddress.lat, venueData.visitingAddress.long),
        map: r
    });

    // Map needle of a room close to the room you are looking at
		let markerOptionNearby1 = new google.maps.Marker({
				icon: "assets/images/mapneedle-xsmall.png",
				position: new google.maps.LatLng(59.3296484, 18.070001300000058),
				map: r
		});

    // Info window
    var contentString1 = '<div id="content">'+
    '<a href="#">' +
    '<h3>Anläggning 1</h3>' +
    '</a>' +
    '<p>Klara S. Kyrkog. 1 Stockholm</p>' +
    '</div>';

    var infowindow1 = new google.maps.InfoWindow({
      content: contentString1
    });

    markerOptionNearby1.addListener('click', function() {
      infowindow1.open(map, markerOptionNearby1);
    });

    // Map needle of a room close to the room you are looking at
    let markerOptionNearby2 = new google.maps.Marker({
        icon: "assets/images/mapneedle-xsmall.png",
        position: new google.maps.LatLng(59.3345856, 18.04202710000004),
        map: r
    });

    // Info window
    var contentString2 = '<div id="content">'+
    '<a href="#">' +
    '<h3>Anläggning 3</h3>' +
    '</a>' +
    '<p>Klara S. Kyrkog. 1 Stockholm</p>' +
    '</div>';

    var infowindow2 = new google.maps.InfoWindow({
      content: contentString2
    });

    markerOptionNearby2.addListener('click', function() {
      infowindow2.open(map, markerOptionNearby2);
    });

    // Map needle of a room close to the room you are looking at
    let markerOptionNearby3 = new google.maps.Marker({
        icon: "assets/images/mapneedle-xsmall.png",
        position: new google.maps.LatLng(59.33558339999999, 18.06146149999995),
        map: r
    });

    // Info window
    var contentString3 = '<div id="content">'+
    '<a href="#">' +
    '<h3>Anläggning 5</h3>' +
    '</a>' +
    '<p>Klara S. Kyrkog. 1 Stockholm</p>' +
    '</div>';

    var infowindow3 = new google.maps.InfoWindow({
      content: contentString3
    });

    markerOptionNearby3.addListener('click', function() {
      infowindow3.open(map, markerOptionNearby3);
    });

	}

  render () {


    return (
			<div className="container" id="venue_map">
				<div className="row">
					<div id="map"></div>
	      </div>
			</div>
    );
  }
}
