import React from 'react'
import './venue.scss';

import MainSlider from '../MainSlider';
import VenueFilters from '../VenueFilters';
import DescriptionData from '../DescriptionData';
import Facilities from '../Facilities';
import OptionsNearby from '../OptionsNearby';
import VenueMap from '../VenueMap';

export default class Venue extends React.Component {
  render() {
    return (
      <div className="venue">

        <div className="row">
          <VenueFilters />
        </div>

        <div className="container">
          <div className="row slider">
            <div className="col-md-9 slider">
              <MainSlider />
            </div>
            <div className="col-md-3 slider">
              <DescriptionData />
            </div>
          </div>
        </div>
        <div className="container facility">
          <div className="row facility">
            <Facilities/>
          </div>
        </div>
      <div className="container">

        <div className="venue_info">
          <div id="venue_rooms"></div>
        </div>

        <div className="row">
          <VenueMap />
        </div>

        <div className="row options">
          <OptionsNearby venue={ 2 } />
        </div>

        </div>

      </div>
    );
  }
}
