import React from 'react';
import ShowSearchForm from '../ShowSearchForm';

export default class RoomsAvailbality extends React.Component {


    render () {
      return (
        <div className="col-sm-6">
          <h3 className="name">Tillgänglighet</h3>
          <ul>
            <li>Parkeringsplatser</li>
            <li>Hiss</li>
            <li>Handikappanpassat</li>

          </ul>
        </div>
      );
    }
}
