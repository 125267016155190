import React from 'react';

import './OptionsNearby.scss';

export default class OptionsNearby extends React.Component {

	state = {
		venues: []
	}

	componentDidMount(){
		var _this=this;
      $.post( options.restURL + "/plantswithindistance/", {plantId: this.props.venue, distanceInMeters: "10000" }, function( a ) {
        a = a.plants_data;
        var r = [];
        var i=0;
        for (var n in a){
          a[n].id = n,
          r[n] = a[n];
          i++;
          if (i === 3) { break; }
        }
        _this.setState({venues: r});
      });
	}

	renderVenue(venue, i){
		var url = options.venuePageUrl + "/" + venue.nameForUrl + "_" + venue.plantId;

		let image;

		if (venue.images) {
        image = venue.images.shift();
    }

		return (
      <div key={`venue_${venue.plantId}`}  className="col-md-4">
        <a className="option_link" href={ url }>
          <div className="option_image" style={{backgroundImage: `url(${image.image})`}}></div>
          <p className="option_name">{ venue.name }</p>
        </a>
      </div>
		);
	}

  render () {
		if(this.state.venues.length == 0){
			return (<div>Loading...</div>);
		}

    return (
      <div>
					<div className="heading">
						<h2 className="name">{ lang.venueOptionsNearby }</h2>
					</div>
					<div className="row">
						{ this.state.venues.map(this.renderVenue) }
					</div>
        </div>

    );
  }
}
