import React from 'react';

import './VenueFilters.scss'

import ShowSearchForm from '../ShowSearchForm';

export default class VenueFilters extends React.Component {

	componentDidMount(){
		$(".venue_filters").sticky();
	}

  render () {
    return (
      <div className="venue_filters">
				<div id="venue_filters">
          <div className="container">
            <div className="row">
              <div className="col-md-7 col-sm-7">
                <h1 className="name">{ venueData.plantName }</h1>
                  <p className="place">
                    {`${venueAddress.street} ${venueAddress.city.name}`}
                  </p>
              </div>
              <div className="col-md-5 col-sm-5">
                <ShowSearchForm />
                  <div className="ok">
                  <p className="form_notice">Ange antal personer och datum för din bokning</p>
                  </div>
              </div>
            </div>
          </div>

				</div>
					<div className="row">

					</div>
      </div>
    );
  }
}
